import React, { useState } from 'react';
import { Button } from 'app/components/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SovAILogo } from 'assets/images';
import { Menu, Xmark } from 'iconoir-react';

const scrollToSection = (sectionId: string, offset = 0) => {
  const section = document.getElementById(sectionId);
  if (section) {
    const offsetPosition = section.offsetTop - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
interface MenuListProps {
  name: string;
  path?: string;
  action?: () => void;
}

const SideBarMenuList = [
  { name: 'Home', path: '/home' },
  { name: 'Profile', path: '/profile' },
  { name: 'Subscribe', path: '/subscribe' },
];

export default function AppBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const location = useLocation();
  const isPublicUrl = location.pathname === '/';

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const { isAuthenticated, isLoading, loginWithRedirect, user } =
    useAuth0();
  const navigate = useNavigate();
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/home',
      },
    });
  };

  const handleLogout = () => {
    window.location.href = '/logout';
  };

  const MenuList: MenuListProps[] = [
    {
      name: 'Features',
      path: '#features',
      action: () => {
        scrollToSection('features');
      },
    },
    {
      name: 'Application',
      path: '',
      action: () => {
        window.open('https://app.sov.ai', '_blank');
      },
    },
    {
      name: 'Pricing',
      path: '#pricing',
      action: () => {
        scrollToSection('pricing');
      },
    },
    {
      name: 'API Key',
      path: '/subscribe',
      action: () => {},
    },
    {
      name: 'Documentation',
      path: '',
      action: () => {
        window.open('https://docs.sov.ai', '_blank');
      },
    },
    {
      name: 'Contact Email',
      path: 'mailto:d.snow@sov.ai',
      action: () => {
        window.open('mailto:d.snow@sov.ai');
      },
    },
  ];

  return (
    <>
      {isPublicUrl ? (
        <div className="w-full flex flex-row py-8 px-6 items-center justify-center">
          <img
            src={SovAILogo}
            alt="SovAI Logo"
            className="object-cover w-40"
            onClick={() => {
              navigate('/');
            }}
          />
          <div className="text-white flex flex-row items-center justify-center px-10 grow">
            {MenuList.map((item, index) =>
              item.path && item.path ? (
                <Link to={item.path}>
                  <div
                    key={index}
                    className="px-4 hidden lg:flex"
                    onClick={item.action ? item.action : () => {}}
                  >
                    {item.name}
                  </div>
                </Link>
              ) : (
                <div
                  key={index}
                  className="px-4 hidden lg:flex cursor-pointer"
                  onClick={item.action ? item.action : () => {}}
                >
                  {item.name}
                </div>
              ),
            )}
          </div>
          {isLoading ? (
            <Button size="small" className="hidden lg:flex text-transparent">
              Login
            </Button>
          ) : isAuthenticated ? (
            <Button
              size="small"
              className="hidden lg:flex"
              onClick={() => {
                navigate('/home');
              }}
            >
              Home
            </Button>
          ) : (
            <Button
              size="small"
              className="hidden lg:flex"
              onClick={() => {
                handleLogin();
              }}
            >
              Login
            </Button>
          )}
          {/* <img src={Menu} alt="Menu" className="w-8 text-white" />
           */}
          <div className="lg:hidden">
            <Menu
              color="white"
              width="2em"
              height="2em"
              onClick={toggleDrawer}
            />
          </div>
          {isDrawerOpen && (
            <div className="absolute top-0 left-0 h-screen w-full bg-gray-900 z-30 py-8 px-6 lg:hidden">
              <div className="flex flex-col">
                <div className="flex flex-row justify-end py-1 relative">
                  <div className="w-full flex flex-row items-center justify-center absolute top-0">
                    {isLoading ? (
                      <Button size="small">Login</Button>
                    ) : isAuthenticated ? (
                      <Button
                        size="small"
                        className="max-w-[250px] w-3/5"
                        onClick={() => {
                          navigate('/home');
                        }}
                      >
                        Home
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        className="max-w-[250px] w-3/5"
                        onClick={() => {
                          handleLogin();
                        }}
                      >
                        Login
                      </Button>
                    )}
                  </div>
                  <div className="z-10">
                    <Xmark
                      color="white"
                      width="2em"
                      height="2em"
                      onClick={toggleDrawer}
                    />
                  </div>
                </div>
                <div className="h-full flex flex-col justify-evenly">
                  <div className="text-white flex flex-col justify-evenly h-96">
                    {MenuList.map((item, index) =>
                      item.path && item.path ? (
                        <Link to={item.path}>
                          <div
                            key={index}
                            className="py-2 text-2xl text-center"
                            onClick={() => {
                              if (
                                item.action &&
                                typeof item.action === 'function'
                              ) {
                                item.action();
                                toggleDrawer();
                              }
                            }}
                          >
                            {item.name}
                          </div>
                        </Link>
                      ) : (
                        <div
                          key={index}
                          className="py-2 text-2xl text-center"
                          onClick={item.action ? item.action : () => {}}
                        >
                          {item.name}
                        </div>
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full flex flex-row py-6 px-6 items-center justify-center relative">
          <div className="flex flex-row grow lg:hidden">
            <Menu
              color="white"
              width="2em"
              height="2em"
              onClick={toggleDrawer}
            />
          </div>
          <div
            className="absolute md:flex flex-row items-center justify-center lg:hidden"
            onClick={() => {
              navigate('/');
            }}
          >
            <img
              src={SovAILogo}
              alt="SovAI Logo"
              className="object-cover w-40"
            />
          </div>
          <div
            className="text-white hidden md:flex flex-row items-center justify-end cursor-pointer grow"
            onClick={() => {
              navigate('/profile');
            }}
          >
            <div>
              {user?.picture ? (
                <div className="w-12 h-12 rounded-full overflow-hidden">
                  <img
                    src={user?.picture}
                    style={{ width: '100%' }}
                    alt="Profile"
                  />
                </div>
              ) : (
                <div className="w-12 h-12 rounded-full overflow-hidden"></div>
              )}
            </div>
            <div className="pl-4">
              {user?.given_name || 'Hello' + ' '} {user?.family_name || 'User'}
            </div>
          </div>
          {isDrawerOpen && (
            <div className="fixed top-0 left-0 h-screen w-4/5 bg-sidebar-gradient z-30 py-8 px-6 lg:hidden">
              <div className="flex flex-col">
                <div className="flex flex-row justify-start py-1">
                  <Xmark
                    color="white"
                    width="2em"
                    height="2em"
                    onClick={toggleDrawer}
                  />
                </div>
                <div className="h-full flex flex-col justify-evenly">
                  <div className="text-white flex flex-col justify-evenly h-96">
                    {SideBarMenuList.map((item, index) => (
                      <div
                        key={index}
                        className="py-2 text-2xl text-center"
                        onClick={() => {
                          navigate(item.path);
                        }}
                      >
                        {item.name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full px-4 pb-8">
                  <Button
                    size="unset"
                    className="w-full py-2"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
